<template>
  <div>
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">Lịch sử giao dịch</div>
      <!-- <div class="header-link">
        <router-link to="/tai-khoan/nap-tien/"
          ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <g transform="translate(-1095 -280)">
              <rect width="12" height="12" transform="translate(1095 280)" fill="#fff" opacity="0" />
              <path
                d="M.152-4.632a.45.45,0,0,0,0,.637l5.5,5.549a.45.45,0,0,0,.637,0L7.036.812a.45.45,0,0,0,0-.637L2.593-4.313,7.036-8.8a.45.45,0,0,0,0-.637l-.742-.742a.45.45,0,0,0-.637,0Z"
                transform="translate(1096.98 290.313)"
                fill="#666"
              />
            </g>
          </svg>
          Nạp tiền</router-link
        >
      </div> -->
    </div>
    <hr class="d-none d-lg-block" />
    <TabHistory class="pb-3 pt-0 pt-lg-3" :isWithdraw="false" />
    <Spinner v-if="depositList.length == 0 && isLoading" />
    <div class="content-list" v-else-if="depositList.length > 0">
      <div
        class="content-block"
        v-for="(deposit, index) in depositList"
        :key="'deposit_' + index"
        @click="$router.push('/tai-khoan/lich-su-nap-tien/' + deposit.display_deposit_id)"
      >
        <div class="deposit-detail">
          <div class="deposit-amount">
            {{ formatPrice(deposit.value) }}
          </div>
          <div class="deposit-date">{{ getOnlyDate(deposit.date) }} {{ getOnlyTime(deposit.date) }}</div>
        </div>
        <div class="deposit-status success-status" v-if="deposit.status == 1">THÀNH CÔNG</div>
        <div class="deposit-status danger-status" v-else-if="deposit.status == 2">THẤT BẠI</div>
        <div class="deposit-status warning-status" v-else-if="deposit.status == 0">ĐANG XỬ LÝ</div>
      </div>
      <Spinner v-if="isLoading" class="d-none d-lg-block" />
      <Spinner v-if="!isLoadEnd" class="d-block d-lg-none" />
      <a class="d-none d-lg-block" @click="scrollBehavior()">
        <b-pagination
          v-if="totalPages > perPage"
          v-model="currentPage"
          :per-page="perPage"
          pills
          hide-ellipsis
          first-number
          last-number
          hide-goto-end-buttons
          :total-rows="totalPages"
          @input="getDepositHistoryPaging()"
          next-class="d-none"
          prev-class="d-none"
        ></b-pagination>
      </a>
    </div>
    <div v-else>Không có lịch sử nạp tiền</div>
  </div>
</template>
<script>
import PaymentService from '@/api/payment';
import Spinner from '@/components/Spinner.vue';
import TabHistory from './TabHistory';

export default {
  layouts: 'DepositHistory',
  components: { Spinner, TabHistory },
  data() {
    return {
      currentPage: 0,
      totalPages: 100,
      depositList: [],
      perPage: 10,
      isLoading: true,
      timeOut: null,
      resizeTimeout: null,
      mobileFlag: false,
      isLoadEnd: false,
      saveWidth: null
    };
  },

  mounted() {
    const isMobile = window.innerWidth <= this.getMobileThresshold();
    if (isMobile) {
      this.getDepositHistory();
    } else {
      this.getDepositHistoryPaging();
    }
  },
  created() {
    window.addEventListener('scroll', this.handlerScroll);
    window.onresize = this.handleResize;
    this.saveWidth = window.innerWidth;
    const isMobile = window.innerWidth <= this.getMobileThresshold();
    const paginationData = this.$store.getters.getterSavePagination;
    if (paginationData && !isMobile) {
      this.currentPage = paginationData;
    } else if (!isMobile) {
      this.currentPage = 1;
    }
    this.$store.commit('setSavePagination', null);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'chi-tiet-lich-su-nap-tien') {
      this.$store.commit('setSavePagination', this.currentPage);
    }
    next();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handlerScroll);
  },
  methods: {
    handlerScroll() {
      let bottomOfWindow =
        window.scrollY + window.innerHeight + 100 >= document.documentElement.scrollHeight &&
        window.innerWidth <= this.getMobileThresshold();

      if (bottomOfWindow) {
        this.loadMore();
      }
    },
    handleResize() {
      if (this.saveWidth === window.innerWidth) {
        return;
      } else {
        this.saveWidth = window.innerWidth;
      }
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (this.mobileFlag === isMobile) {
        return;
      }
      this.mobileFlag = isMobile;

      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.currentPage = 1;
        this.isLoadEnd = false;
        this.depositList = [];
        if (isMobile) {
          this.getDepositHistory();
        } else {
          this.getDepositHistoryPaging();
        }
      }, 1000);
    },
    loadMore() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.isLoading = true;
      this.timeOut = setTimeout(() => {
        // this.currentPage++;
        this.getDepositHistory();
        this.isLoading = true;
      }, 1000);
    },
    getDepositHistory() {
      if (this.isLoadEnd) {
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      PaymentService.getDepositHistory(this.currentPage + 1)
        .then((res) => {
          if (res.data.status == true) {
            // if (res.data.data.depositList.length === 0) {
            //   this.isLoadEnd = true;
            //   this.isLoading = false;
            //   return;
            // }

            if (
              res.data.data?.depositList?.length + this.perPage * this.currentPage >= res.data.data.total ||
              res.data.data?.depositList?.length === res.data.data.total
            ) {
              this.isLoadEnd = true;
            }
            this.depositList = this.depositList.concat(res.data.data.depositList);
            this.currentPage++;
          }
          this.isLoading = false;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getDepositHistoryPaging() {
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (isMobile) {
        this.isLoading = false;

        return;
      }
      this.isLoading = true;
      PaymentService.getDepositHistory(this.currentPage)
        .then((res) => {
          if (res.data.status == true) {
            this.totalPages = res.data.data.total;
            this.depositList = res.data.data.depositList;
          }
          this.isLoading = false;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    scrollBehavior() {
      document.getElementById('scroll').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};
</script>
